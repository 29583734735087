<template>
  <div>
    <v-row>
      <v-col offset-md="3" md="6" sm="6" offset-sm="3">
        <v-card outlined>
          <v-card-text>
            <img src="@/assets/form.svg" class="main-banner" />
            <v-alert type="error" v-show="errors.message">{{errors.message}}</v-alert>
            <v-text-field
              label="Email"
              hide-details="auto"
              prepend-icon="mdi-account"
              :error-messages="errors.email"
              v-model="user.email"
              v-on:keyup.enter="login()"
            ></v-text-field>
            <v-text-field
              label="Password"
              hide-details="auto"
              prepend-icon="mdi-lock"
              type="password"
              :error-messages="errors.password"
              v-model="user.password"
              v-on:keyup.enter="login()"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-toolbar flat>
              <v-btn text color="green" @click="login"
                ><v-icon left >mdi-send</v-icon>Login</v-btn
              >
              <v-btn text color="blue" to="/register"
                ><v-icon left>mdi-account-plus</v-icon>Register</v-btn
              >
              <v-btn text color="red" to="/forgot"
                ><v-icon left>mdi-email-fast-outline</v-icon>Forgot</v-btn
              >
            </v-toolbar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAPI from '@/lib/APIUser'

export default {
  data() {
    return {
      user: {},
      errors: {},
    }
  },
  methods: {
    login() {
      UserAPI.login(this.user, (resp) => {
        UserAPI._login(resp.token)
        this.errors = {}
      },
      (resp) => this.errors = resp.response.data)
    }
  }
};
</script>

<style>
.main-banner {
  width: 100%;
}
</style>