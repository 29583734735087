<template>
  <div>
    <v-row>
      <v-col offset-md="3" md="6">
        <v-card outlined>
          <v-card-text>
              <img src="@/assets/forgot.svg" class="main-banner" />
            <v-text-field
              label="Username"
              hide-details="auto"
              prepend-icon="mdi-account"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-toolbar flat>
              <v-btn text color="red"><v-icon left>mdi-email-fast-outline</v-icon>Forgot</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="green" to="/login"><v-icon left>mdi-send</v-icon>Login</v-btn>
            </v-toolbar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
.main-banner {
  width: 100%;
}
</style>