import http from "./http"
import {URLCollection} from "./consts"

export default {
    get(data, success) {
        http.get(URLCollection + `/${data.id}`, "", success, () => false)
    },

    preview(id, success) {
        http.get(URLCollection + `/${id}/preview`, "", success, () => false)
    },

    create(data, success, failure) {
        http.post(URLCollection, data, success, failure)
    },

    edit(data, success, failure) {
        http.put(URLCollection, data, success, failure)
    },

    delete(data, success) {
        http.delete(URLCollection + `/${data}`, null, success)
    }

}