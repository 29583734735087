import axios from "axios"
var ax = axios.create({
     baseURL: process.env.VUE_APP_API_BASE_URL,
     //withCredentials: true
})

ax.interceptors.request.use(function(config) {
     var token = localStorage.getItem("token")
     if(token) {
          config.headers.Authorization = "Bearer " + token
     }
     return config;
})

export default ax