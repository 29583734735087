export const AppInfo = {
    AppName: "seciniz-ui",
    AppDeveloper: "Kenan Erarslan",
    AppDeveloperEmail: "kenan@enginaar.com"
  };
  
  const api = ""
  const apiAuthBase = api + "/auth"
  
  export const URLCollection = api + "/collection"
  export const URLBlock = api + "/block"
  export const URLOption = api + "/option"
  export const URLVote = api + "/vote"
  
  export const apiAuth = {
    LoginStatus: apiAuthBase + "/loginStatus",
    LoginAccount: apiAuthBase + "/loginAccount",
    Login: apiAuthBase + "/login",
    Logout: apiAuthBase + "/logout"
  }