<template>
  <div>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-text>
            <h1>{{ collection.title }}</h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-for="(i, j) in collection.blocks" :key="j">
      <v-col v-if="i.type == 'static'">
        <v-card outlined>
          <v-card-text>{{ i.title }}</v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="i.type == 'question'">
        <v-card outlined>
          <v-card-title>{{ i.title }}</v-card-title>
          <v-card-text>
            <div v-if="i.option_type == 'MULTI_SELECTION'">
              <v-checkbox
                hide-details="auto"
                v-for="c in i.options"
                :key="c.id"
                 :label="c.title + ' - ' + c.id"
                v-model="c.selected"
              ></v-checkbox>
            </div>
            <div v-if="i.option_type == 'SINGLE_SELECTION'">
              <v-radio-group v-model="i.selected">
                <v-radio
                  v-for="c in i.options"
                  :key="c.id"
                  :value="c.id"
                  :label="c.title + ' - ' + c.id"
                ></v-radio
              ></v-radio-group>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="i.type == 'input'">
        <v-card outlined>
          <v-card-title>{{ i.title }}</v-card-title>
          <v-card-text>
            <v-text-field dense class="mb-2" outlined hide-details="auto" v-for="t in i.number_of_options" :key="t" v-model="i.options[t]"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-toolbar outlined flat>
          <v-spacer></v-spacer>
          <v-btn text @click="send"><v-icon>mdi-send</v-icon></v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from '@/lib/APICollection'
import VoteAPI from '@/lib/APIVote'
export default {
  data() {
    return {
      collection: {}
    };
  },
  methods: {
    send() {
      let ob = {}
      ob.collection_id = this.collection.id
      ob.blocks = []
      for(const blk of this.collection.blocks) {
        if (blk.type == 'input') {
          for(const op of blk.options) {
            if(op == undefined) continue
              ob.blocks.push({
                "block_id": blk.id,
                "value": op
              })
          }
        }
        if(blk.type == 'question') {
          if(blk.option_type == 'SINGLE_SELECTION') {
            ob.blocks.push({
              "block_id": blk.id,
              "option_id": blk.selected,
              "value": true
            })
          }
          if(blk.option_type == 'MULTI_SELECTION') {
            for(const op of blk.options) {
              if (op.selected) {
                ob.blocks.push({
                  "block_id": blk.id,
                  "option_id": op.id,
                  "value": true
                })
              }
            }
          }
        }
      }
      VoteAPI.create(ob, null, null)
    }
  },
  mounted() {
    let collectionId = this.$route.params.id;
    API.preview(collectionId, (body) => {
      this.collection = body.data
    });
  },
  computed: {
    id() {
      return this.$route.params.id
    }
  }
};
</script>

<style>
</style>