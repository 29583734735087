import axios from "@/plugins/axios"

//https://github.com/ybkuroki/vuejs-webapp-sample/blob/master/src/api/ajax.js

export default class Http {
    static get(url, data = {}, success, failure) {
        const params = this.createParameter(data)
        axios
            .get(url, {params: params})
            .then(res => {
                if(success) success(res.data)
            })
            .catch(err => {
                if(failure) failure (err)
            })
    }

    static post(url, data = {}, success, failure) {
        axios
            .post(url, data)
            .then(res => {
                if(success) success(res.data)
            })
            .catch(err => {
                if(failure) failure (err)
            })
    }

    static put(url, data = {}, success, failure) {
        axios
            .put(url, data)
            .then(res => {
                if(success) success(res.data)
            })
            .catch(err => {
                if(failure) failure (err)
            })
    }

    static delete(url, data = {}, success, failure) {
        axios
          .delete(url, data)
          .then(res => {
            if (success) success(res.data)
          })
          .catch(err => {
            if (failure) failure(err)
          })
      }

      static createParameter(data = {}) {
        const params = new URLSearchParams();
        Object.keys(data).forEach(key => {
          if (data[key]) {
            params.append(key, data[key]);
          }
        });
        return params
      }
}