import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import Router from './router'
import Axios from '@/plugins/axios'

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue() 

Vue.prototype.$http = Axios

new Vue({
  vuetify,
  render: h => h(App),
  router: Router
}).$mount('#app')
