import VueRouter from "vue-router"
import Home from "@/pages/index/public/Index"
import Login from '@/pages/index/public/Login'
import Register from '@/pages/index/public/Register'
import Forgot from '@/pages/index/public/Forgot'
import SurveyVote from '@/pages/index/Survey/SurveyVote'
import Vue from "vue";

Vue.use(VueRouter)
export default new VueRouter({
    routes: [
        {
            path: "/",
            name: 'Home',
            component: Home
        },
        {
            path: "/login",
            name: 'Login',
            component: Login
        },
        {
            path: "/register",
            name: 'Register',
            component: Register
        },
        {
            path: "/forgot",
            name: 'Forgot',
            component: Forgot
        }, {
            path: "/survey/:id/vote",
            name: "Vote",
            component: SurveyVote
        }
    ]
})