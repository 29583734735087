import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify,{
    icons: {
        theme:{
            primary: '#9652ff'
        },
        
      },
});

export default new Vuetify({
    
});