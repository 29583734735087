<template>
  <div>
    <v-row>
      <v-col offset-md="3" md="6">
        <v-card outlined>
          <v-card-text>
            <img src="@/assets/team-up.svg" class="main-banner" />
             <v-text-field
              label="Name"
              hide-details="auto"
              prepend-icon="mdi-account"
              type="email"
              :error-messages="errors.name"
              v-model="user.name"
            ></v-text-field>
            <v-text-field
              label="Email Address"
              hide-details="auto"
              prepend-icon="mdi-account"
              :error-messages="errors.email"
              v-model="user.email"
            ></v-text-field>
            <v-text-field
              label="Password"
              hide-details="auto"
              prepend-icon="mdi-lock"
              type="password"
              :error-messages="errors.password"
              v-model="user.password"
            ></v-text-field>
            <v-text-field
              label="Password (Repeat)"
              hide-details="auto"
              prepend-icon="mdi-lock"
              :error-messages="errors.password"
              type="password"
              v-model="user.password_confirmation"
            ></v-text-field>
            <v-alert v-show="errors.message" color="error">
               {{ errors.message }} 
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-toolbar flat>
              <v-btn text color="blue" @click="register"
                ><v-icon left>mdi-account-plus</v-icon>Register</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn text color="green" to="/login"
                ><v-icon left>mdi-send</v-icon>Login</v-btn
              >
            </v-toolbar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAPI from "@/lib/APIUser";

export default {
  data() {
    return {
      user: {},
      errors: {}
    };
  },
  methods: {
    register() {
      UserAPI.register(
        this.user,
        () => {
          this.errors = {}
          
        },
        (body) => {
          this.errors = (body.response.data)
        }
      );
    },
  }
};
</script>

<style>
.main-banner {
  width: 100%;
}
</style>