<template>
  <div>
    <v-row>
      <v-col>
        <v-row align="center">
          <v-col cols="4">
            <h2>
              Do you need a simple and effective survey maker with mobile first
              approach?
            </h2>
            <p class="body-2">Seciniz.com is a free, simple, mobile first online survey maker</p>
          </v-col>
          <v-col cols="8">
            <v-card>
              <v-card-text>
                <img src="@/assets/survey.svg" class="main-banner" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
.main-banner {
  width: 100%;
}
</style>