<template>
    <v-app-bar app color="grey lighten-3" elevate-on-scroll>
      <v-toolbar-title class="text-uppercase grey--text" @click="$router.push('/')">
        <v-avatar size="30"><img src="https://img.icons8.com/dotty/80/000000/circled-s.png"/></v-avatar>
        <span class="font-weight-light" to="/">Seciniz</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="grey" v-if="!loggedIn" to="/login">
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <v-btn text color="grey" v-if="loggedIn" @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
</template>

<script>
import UserAPI from '@/lib/APIUser'

export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token")
      window.location = "/"
    }
  },
  computed: {
    loggedIn () {
      return UserAPI.loggedIn()
    }
  }
};
</script>