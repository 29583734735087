<template>
  <v-app>
    <top-bar />
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/TopBar"
import UserAPI from '@/lib/APIUser'

export default {
  name: "App",

  components: { TopBar },

  data: () => ({
    //
  }),
  mounted() {
    if(UserAPI.loggedIn()) {
      UserAPI.me(() => window.location = "/dashboard.html")
    } else {
      console.log("not logged in")
    }
  }
};
</script>
<style>
</style>