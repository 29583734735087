import http from "./http"

export default {
    me(success) {
        http.get('/me', "", success, () => false)
    },

    login(data, success, failure) {
      http.post('/login', data, success, failure)  
    },

    register(data, success, failure) {
        http.post('/register', data, success, failure)
    },

    loggedIn() {
        var token = localStorage.getItem("token")
        if(token)
            return true
        return false
    },
    
    _login(token) {
        localStorage.setItem("token", token)
        window.location = "/dashboard.html"
    },

    _logout() {
        localStorage.removeItem("token")
        window.location = "/"
    },

    _checkLoggedIn() {
        if(!this.loggedIn())
            window.location = "/"
    }
}